import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class SliderService {

  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }

  getImages() {
    return this.httpClient.get<any>(this.readingSettingsData.getSchoolDomain() + "/website/slider/getImages");
  }
  checkIfSliderIsVedio(){

    return this.httpClient.get<any>(this.readingSettingsData.getSchoolDomain() + "/website/slider/checkIfSliderIsVedio");

  }
}
