import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class GradesService {
  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }

  getGrades(stageId: number,depid:number) {
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/grades/getGrades?stageId=" + stageId +"&deptId="+depid);
  }
  getAllGrades(){
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/grades/getAllGrades");


  }
  ValidateOctoberAge(birthdate: any, subphaseId: number, deptId: number) {
    console.log(birthdate);

    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/grades/ValidateAgeToOctober?birthdate=" + birthdate + "&subphaseId=" + subphaseId + "&deptId=" + deptId);
  }
}
