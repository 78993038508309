<div class="Content2Column">
  <div class='row'>
    <img src="{{domainUrl+pathData.about+eventData.imgUrl}}" alt="">
  </div>
  <div class="row">
    <div class="col-lg-2">تاريخ البداية</div>
    <div class="col-lg-10">{{eventData.startDate|date:"MMM yyyy"}}</div>
  </div>
  <div class="row">
    <div class="col-lg-2">تاريخ الانتهاء</div>
    <div class="col-lg-10">{{eventData.endDate|date:"MMM yyyy"}}</div>
  </div>
  <div class="row">
    <div class="col-lg-2">النص</div>
    <div [innerHTML]="eventData.text" class="col-lg-10"></div>
  </div>

</div>
