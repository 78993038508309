import { Component, OnInit } from '@angular/core';
import { aboutFullData } from 'src/app/core/models/aboutFullData';
import { AboutService } from 'src/app/core/services/about.service';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  constructor(private aboutService: AboutService, private readingSettingsData: ReadingSettingsDataService) {
    this.getData();

  }
  aboutData = new aboutFullData();
  domainUrl = this.readingSettingsData.getSchoolDomain();
  getData() {
    this.aboutService.getFullData().subscribe((data: any) => this.aboutData = data)
  }

  ngOnInit(): void {
  }

}
