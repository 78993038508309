
 
                <!-- side menue-->
                    <div class="ColumnLeft">
                      <nav id="SideHeadingPages" class="SideHeading">
                        <h2>{{'RelatedPages'|translate}}</h2>
                        <ul class="SideList">
                          <li><a style="text-align: center;" routerLink="" id="nav1125">{{'home'|translate}}</a></li>
                          <li><a style="text-align: center;" routerLink="/about" id="nav1126">{{'about'|translate}}</a></li>
                          <li><a style="text-align: center;" routerLink="/news" id="nav1127">{{'news'|translate}}</a> </li>
                          <li><a style="text-align: center;" routerLink="/events" id="nav1127">{{'events'|translate}}</a> </li>
                          <li><a style="text-align: center;" routerLink="/apply" id="nav1127">{{'applynow'|translate}}</a> </li>
             
                        </ul>
                      </nav>
                    </div>
  
            