import { Component, OnInit } from '@angular/core';
import { newsMiniData } from 'src/app/core/models/newsMiniData';
import { paginationConfig } from 'src/app/core/models/paginationConfig';
import { NewsService } from 'src/app/core/services/news.service';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  config = new paginationConfig();
  domainUrl: string = this.readingSettingsData.getSchoolDomain();
  constructor(private newsService: NewsService, private readingSettingsData: ReadingSettingsDataService, private router: Router) {
    this.loadNewsData();
  }

  newsData: newsMiniData[] = [];
  loadNewsData() {
    this.newsService.getNewsByPage(this.config).subscribe((data: any) => {
      this.newsData = data.data;
      this.config.totalItems = data.totalRecords;
    })
  }

  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.loadNewsData();
  }
  ngOnInit(): void {
  }
  NavigateToNewDetails(newsItem: newsMiniData) {
    this.router.navigate(['/newDetails', newsItem.id])

  }
}
