import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { paginationConfig } from 'src/app/core/models/paginationConfig';
import { JobsService } from 'src/app/core/services/jobs.service';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
// import { MdbModalRef ,MdbModalService } from 'mdb-angular-ui-kit/modal';
// import { ModalComponent } from 'src/app/modal/modal.component';
// import { DataTablesModule } from 'angular-datatables';
// import {BrowserModule} from '@angular/platform-browser';
import {HttpClient} from '@angular/common/http';
// import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.css']
})
export class JobDetailsComponent implements OnInit {
  public newId: any;
  jobData: any ;
  config = new paginationConfig();
  CvPath:any;
  constructor(private http: HttpClient,private route: ActivatedRoute, private JobsService: JobsService, private translate: TranslateService, private router: Router) { }

  ngOnInit(): void {
    $('#Jobdetail').hide();
    this.route.params.subscribe(params => {
      this.newId = params['newId'];
    });
    this.loadjobData();
  }
  loadjobData() {
    $('#ApplyForm').hide();
    this.JobsService.getjobById(this.newId).pipe().toPromise().then((data: any) => {
      this.jobData = data.data;
      console.log(this.jobData);
      $('#Jobdetail').show("slow","swing");
    });
  }
  ShowApplyForm(){
    $('#Jobdetail').hide();
    $('#ApplyForm').show("slow","swing");

  }
  handleFileInput(event: any, type: any) {
    if (event.target.files.length > 0) {
      if (type == "Cvpath") {
        this.CvPath = event.target.files[0];
        console.log(event.target.files[0].name)
        $('#Inputlbl').html(event.target.files[0].name);
      }
    }
    else{
      this.CvPath=undefined;
      $('#Inputlbl').html(this.translate.instant('cv'));
    }
  }
  SendData(){
    var obj = {
      FullName: $('#FullName').val(),
      Email: $('#Email').val(),
      mobilePhone: $('#mobilePhone').val(),
      PhoneNumber: $('#PhoneNumber').val(),
      CvPath: this.CvPath,
      JobApplicationId:$('#JobId').val()
    }
    if (this.formvalid(obj)) {
      console.log(obj);
      this.JobsService.PostJobApplyRequest(obj).pipe().toPromise().then((res:any)=>{
        console.log(res.body);
        debugger;
        if (res.body == 1) {
          Swal.fire(this.translate.instant('goodJob'), this.translate.instant('appliedsuccessfully'), 'success');
          this.router.navigate(["/jobs"]);
        }else if (res.body == 2) {
          Swal.fire(this.translate.instant('payAttention'), this.translate.instant('fileNotSupported'), 'warning');
        }else{
          Swal.fire(this.translate.instant('payAttention'), this.translate.instant('ErrorPleaseTryAgian'), 'warning');
        }
      },(err)=>{
        console.log(err.error)
      })
    }
  }
  formvalid(obj: any) {
    if (obj.FullName == "") {
      $('#FullName').css("border-color", "red")
      return false;
    }
    else
      $('#FullName').css("border-color", "")
    if (obj.Email == "" || !(obj.Email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))) 
    {
      $('#Email').css("border-color", "red")
      return false;
    } else
      $('#Email').css("border-color", "")

    if (obj.mobilePhone == "") {
      $('#mobilePhone').css("border-color", "red")
      return false;
    } else
      $('#mobilePhone').css("border-color", "")

    if (obj.PhoneNumber == "") {
      $('#PhoneNumber').css("border-color", "red")
      return false;
    } else
      $('#PhoneNumber').css("border-color", "")

    if (this.CvPath == null || this.CvPath == undefined) {
      $('#cv').css("border-color", "red")
      $('#Inputlbl').css("border-color", "red")
      return false;
    } else{
      $('#cv').css("border-color", "")
      $('#Inputlbl').css("border-color", "")
    }

    return true;
  }


}
