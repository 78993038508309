<header>
  <app-school-logo-and-name [schoolData]="schoolData"></app-school-logo-and-name>
  <div class="home-links">
    <a href="#" class="btn button-small" onclick="toggelSignInModal()"
      (click)="riseSignInModal()">{{'SignIn'|translate}}</a>
    <app-language-select></app-language-select>
    <app-schools-select></app-schools-select>
    <app-signin (changeMessageValue)="setMessageValue($event)" [message]="signinMessage"></app-signin>
    <ul class="social-header">
      <li><a [href]="schoolData.facebook" target="_blank"><i class="fa fa-facebook"></i></a></li>
      <li><a [href]="schoolData.twitter" target="_blank"><i class="fa fa-twitter"></i></a></li>
      <li><a [href]="schoolData.instgram" target="_blank"><i class="fa fa-instagram"></i></a></li>
      <li><a [href]="schoolData.youtube" target="_blank"><i class="fa fa-youtube"></i></a></li>
    </ul>
  </div>


</header>
