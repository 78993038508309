import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { newDetaisData } from 'src/app/core/models/newDetail';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { NewDetailsService } from 'src/app/core/services/newDetailsService';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { paginationConfig } from 'src/app/core/models/paginationConfig';

@Component({
  selector: 'app-new-details',
  templateUrl: './new-details.component.html',
  styleUrls: ['./new-details.component.css']
})
export class NewDetailsComponent implements OnInit {
  public newId: any;
  newData: newDetaisData = new newDetaisData()
  pathData = new attachmentsPaths();
  domainUrl: string = this.settingsData.getSchoolDomain();
  config = new paginationConfig();

  constructor(private route: ActivatedRoute, private newDetailsService: NewDetailsService,
    private settingsData: ReadingSettingsDataService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.newId = params['newId'];
    });


    this.loadNewData();
  }
  loadNewData() {
    this.newDetailsService.getNewById(this.newId).subscribe((data: any) => {
      this.newData = data.data;
      console.log(this.newData)
    })

  }
}
