import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';
@Injectable({
    providedIn: 'root'
  })
  export class TermOfJoinDetailsService {
    constructor(private httpClient: HttpClient, private readindSettingsData: ReadingSettingsDataService) { }
        getTermOfJoin ( ) {
        return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/termOfJoinDetails/getDetail   "    )
      }
  }
 