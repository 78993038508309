import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { param } from 'jquery';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { eventsData } from 'src/app/core/models/eventsData';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {
  public eventId: any;
  eventData: eventsData = new eventsData;
  pathData = new attachmentsPaths();
  domainUrl: string = this.settingsData.getSchoolDomain();
  constructor(private route: ActivatedRoute, private eventsService: EventsService, private settingsData: ReadingSettingsDataService) { }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe(params => { this.eventId = params; });
    this.loadEventData();
  }

  loadEventData() {
    this.eventsService.getEventById(this.eventId).subscribe((data: any) => {
      this.eventData = data;
    })
  }

}
