import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cookieHelper } from 'src/app/core/helpers/cookieHelper';
import { schoolDomain } from '../../models/schoolDomain';
@Injectable({
  providedIn: 'root'
})
export class ReadingSettingsDataService {
  constructor(private httpclient: HttpClient, private cookieHelper: cookieHelper, private router: Router) {
  }

  public schoolDomains: schoolDomain[] = [
    {
      publicWebSiteHostName: "newschool.schoolonlinepro.com", onlineSchoolDomain: "https://newonline.schoolonlinepro.com"
    },
    {
      publicWebSiteHostName: "rowadpro.rowadedu.com", onlineSchoolDomain: "https://rowadonlinepro.rowadedu.com"
    },
    {
      publicWebSiteHostName: "alawaelonline.alawaelschools.net", onlineSchoolDomain: "https://alawaelpro.alawaelschools.net"
    },
    {
      publicWebSiteHostName: "olspro.ols-eg.org", onlineSchoolDomain: "https://onlinepro.ols-eg.org"
    },
    {
      publicWebSiteHostName: "localhost", onlineSchoolDomain: "https://localhost:44356"
    }
    ,
    {
      publicWebSiteHostName: "admission.adminwinportal.com", onlineSchoolDomain: " https://admin.adminwinportal.com"
    }
    ,
    {
      publicWebSiteHostName: "bayanschools.com", onlineSchoolDomain: "https://lms.bayanschools.com"
    }
    ,
    {
      publicWebSiteHostName: "rlschools.com", onlineSchoolDomain: "https://lms.rlschools.com"
    }
    ,
    {
      publicWebSiteHostName: "olps-h.com", onlineSchoolDomain: "https://lms.olps-h.com"
    }
    ,
    {
      publicWebSiteHostName: "olps-nh.com", onlineSchoolDomain: "https://lms.olps-nh.com"
    }
  ];

  getSchoolDomain() {
    var currentHostName = window.location.hostname.toString();
    var OnlineSchoolDomain = this.getOnlineSchoolDomain(currentHostName);
    return OnlineSchoolDomain;
  };

  getOnlineSchoolDomain(currentPublicWebSitDomain: string) {
    var domain = "";
    this.schoolDomains.forEach((element) => {
      if (element.publicWebSiteHostName === currentPublicWebSitDomain) {
        domain = element.onlineSchoolDomain;
      }
    })
    return domain;
  }
}
