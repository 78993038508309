<div id="JobResults">
    <h1>{{ 'Jobs' | translate }}</h1>
    <table class="table table-striped table-hover text-center" id="AppTable">
        <thead>
            <tr>
                <th>{{ 'JobTitle' | translate }}</th>
                <th>{{ 'WorkPlace' | translate }}</th>
                <th>{{ 'NeededNumber' | translate }}</th>
                <th>{{ 'QualificationRequired' | translate }}</th>
                <th>{{ 'LastDateApplying' | translate }}</th>
                <th>{{ 'ExYearsCount' | translate }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let JobItem of JobsData; let index = index">
                <td>{{JobItem.jobName}}</td>
                <td>{{JobItem.workPlace}}</td>
                <td>{{JobItem.numberNedded}}</td>
                <td>{{JobItem.qualificationName}}</td>
                <td>{{JobItem.lastDate}}</td>
                <td>{{JobItem.exYears}}</td>
                <td><button type="button" (click)="NavigateToJobDetails(JobItem)" class="btn btn-primary m-1">{{'ApplyNow'|translate}}</button>
                </td>
            </tr>

        </tbody>
    </table>
</div>

<!-- <div class="Content2Column">
    <h1>{{ 'Jobs' | translate }}</h1>
    <div class="box-grid-list">
      <a id="add-list" class="active"><img src="assets/img/icons/ic-list.png" alt="Show list"></a>
      <a id="add-grid" class=""><img src="assets/img/icons/ic-grid.png" alt="Show Grid"></a>
    </div>
    <ul id="JobsListing" class="list-view">
      <li *ngFor="let JobItem of JobsData| paginate: config">

        <div class="text-box">
          <div class="title"><a href="" (click)="NavigateToJobDetails(JobItem)">{{JobItem.jobName}}</a></div>
          <div class="date">{{'LastDateApplying' | translate}} {{JobItem.lastDate|date:"dd/MM/yyyy"}}</div>
          <div class="page-overview">{{JobItem.jobRequirments}}</div>
        </div>
      </li>
    </ul>
    <pagination-controls [responsive]=true previousLabel="{{'previos'|translate}}" nextLabel="{{'next'|translate}}"
      (pageChange)="pageChange($event)">
    </pagination-controls>
  </div> -->
