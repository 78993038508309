import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';

import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import {  TermOfJoinDetaisData } from 'src/app/core/models/TermOfJoinDetail';
import { TermOfJoinDetailsService } from 'src/app/core/services/termOfJoinDetailsService';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { paginationConfig } from 'src/app/core/models/paginationConfig';
@Component({
  selector: 'app-TermOfJoinDetails-details',
  templateUrl: './TermOfJoinDetails-details.component.html',
  styleUrls: ['./TermOfJoinDetails-details.component.css']
})
export class TermOfJoinDetailsComponent implements OnInit {
  public newId: any;
  TermOfJoinDetailsData: TermOfJoinDetaisData = new TermOfJoinDetaisData()
  pathData = new attachmentsPaths();
  domainUrl: string = this.settingsData.getSchoolDomain();
  config = new paginationConfig(); 
  constructor(private route: ActivatedRoute, private termOfJoinDetailsService: TermOfJoinDetailsService,
    private settingsData: ReadingSettingsDataService) { } 
  ngOnInit(): void { 
    this.loadNewData();
  }
  
  loadNewData() {
    this.termOfJoinDetailsService.getTermOfJoin ( ).subscribe((data: any) => {
      
      this.TermOfJoinDetailsData = data.data;
      console.log(this.TermOfJoinDetailsData)
    })

  }
}
