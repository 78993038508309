<div class="text-center" id="Jobdetail">
    <h1 class="posttitle"> {{jobData.jobName}} </h1>
    <table class="table table-bordered table-hover text-center">
        <tr>
            <th>{{'WorkPlace' | translate}}</th>
            <td> {{jobData.workPlace}} </td>
        </tr>
        <tr>
            <th>{{'NeededNumber' | translate}}</th>
            <td> {{jobData.numberNedded}} </td>
        </tr>
        <tr>
            <th>{{'QualificationRequired' | translate}}</th>
            <td> {{jobData.qualificationName}} </td>
        </tr>
        <tr>
            <th>{{'LastDateApplying' | translate}}</th>
            <td> {{jobData.lastDate}} </td>
        </tr>
        <tr>
            <th>{{'ExYearsCount' | translate}}</th>
            <td> {{jobData.exYears}} </td>
        </tr>
        <tr>
            <th>{{'SkillsRequired' | translate}}</th>
            <td> {{jobData.skills}} </td>
        </tr>
        <tr>
            <th>{{'JobRequirments' | translate}}</th>
            <td [innerHTML]="jobData.jobRequirments"> </td>
        </tr>
        <tr>
            <th>{{'notes' | translate}}</th>
            <td [innerHTML]="jobData.Notes"></td>
        </tr>
    </table>
    <button type="button" (click)="ShowApplyForm()"
        class="text-center btn btn-primary m-1">{{'ApplyNow'|translate}}</button>
</div>
<input id="JobId" value="{{jobData.Id}}" hidden>
<div class="w-50 card" id="ApplyForm">
    <div class="card-header">
        <h1 class="card-title">
            {{'applyforJob' | translate}}
        </h1>
    </div>
    <div class="card-body">
        <form>
            <div class="mb-3 w-50 text-center" style="display: inline;">
                <label for="FullName" class="form-label"></label>
                <input type="email" placeholder="{{'FullName' | translate}}" class="form-control" id="FullName">
            </div>
            <div class="mb-3 w-50" style="display: inline;">
                <label for="Email" class="form-label"></label>
                <input type="email" placeholder="{{'Email' | translate}}" class="form-control" id="Email" aria-describedby="emailHelp">
            </div>
            <div class="mb-3 w-50" style="display: inline;">
                <label for="mobilePhone" class="form-label"></label>
                <input type="number" placeholder="{{'mobilePhone' | translate}}" class="form-control" id="mobilePhone" aria-describedby="emailHelp">
            </div>
            <div class="mb-3 w-50" style="display: inline;">
                <label for="PhoneNumber" class="form-label"></label>
                <input type="number" placeholder="{{'PhoneNumber' | translate}}" class="form-control" id="PhoneNumber" aria-describedby="emailHelp">
            </div>
            <div class="mb-3 w-50" style="display: inline;">
                <label  class="form-label"></label>
                <label id="Inputlbl" style="margin: 0px 0px 20px 0px; color: #999; padding: 2px; border: 1px solid #ced4da;border-radius: .25rem;" for="cv" class="label-tag text-center">{{'cv'|translate}}</label>
                <input hidden accept="application/pdf , image/* ,application/docx" type="file" placeholder="{{'cv'|translate}}"
                    (change)="handleFileInput($event,'Cvpath')" id="cv" class="form-control">
            </div>
        </form>
        <div class="card-footer">
            <button type="button" (click)="SendData()" class="btn btn-primary">{{'Savedata' | translate}}</button>

        </div>
    </div>
</div>

