import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { paginationConfig } from '../models/paginationConfig';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private httpClient: HttpClient, private readindSettingsData: ReadingSettingsDataService) { }

  getLatestNews() {
    return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/news/getLatestNews");
  }
  getNewsByPage(pagingConfigs: paginationConfig) {
    return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/news/getNewsByPage?pageNumber=" + pagingConfigs.currentPage + "&&size=" + pagingConfigs.itemsPerPage);
  }
  getNewById(newId: number) {
    return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/news/newsDataById?newsId=" + newId);
  }
}
