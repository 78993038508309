import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { keyValue } from 'src/app/core/models/keyValue';

export function devisionValidator(devisions: keyValue[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (value == null && devisions.length > 0) {
      return { isNull: true };
    } else {
      return null;
    }
  }
}
