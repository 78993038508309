export class schoolData {
  name: string = '';
  logo: string = '';
  phone: string = '';
  address: string = '';
  mail: string = '';
  facebook: string = '';
  youtube: string = ''
  instgram: string = '';
  twitter: string = '';
  googleMap:string='';
  About:string='';
}
