import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { loginForm } from '../models/loginForm';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }
  login(loginData: loginForm) {
    const headerDict = {
      'Accept': 'application/json',
    }
    return this.httpClient.post<any>(this.readingSettingsData.getSchoolDomain() + '/website/login', loginData, { headers: headerDict });
  }
}
