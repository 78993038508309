import { Component, OnInit } from '@angular/core';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { SliderService } from 'src/app/core/services/slider.service';
declare const loadScliderImages: any;
@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {

  constructor(private sliderService: SliderService, private readingSettingsData: ReadingSettingsDataService) { }

  ngOnInit(): void {
    this.sliderService.getImages().subscribe(x => {
      loadScliderImages(x, this.readingSettingsData.getSchoolDomain());
    })
  }

}
