import { Component,Input, OnInit } from '@angular/core';
import { LoadScriptsService } from 'src/app/core/globalFunctions/load-scripts.service';
import { schoolData } from 'src/app/core/models/schoolData';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { SchoolDataService } from 'src/app/core/services/school-data.service';


@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent implements OnInit {
  constructor(private loadPageScripts: LoadScriptsService, private schoolDataService: SchoolDataService,private readingSettingsData: ReadingSettingsDataService) {
    this.getSchoolData();
  }
  doaminName: string = this.readingSettingsData.getSchoolDomain();
  @Input() schoolData = new schoolData();  
  getSchoolData() {
    this.schoolDataService.getSchoolData().subscribe((data: any) => this.schoolData = data);
  }
  ngOnInit(): void {
    this.loadPageScripts.LayoutScripts();
  }
}
