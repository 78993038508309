import { Component, OnInit } from '@angular/core';
import { LoadScriptsService } from 'src/app/core/globalFunctions/load-scripts.service';
import { cookieHelper } from 'src/app/core/helpers/cookieHelper';
import { keyValue } from 'src/app/core/models/keyValue';
import { schoolData } from 'src/app/core/models/schoolData';
import { SchoolDataService } from 'src/app/core/services/school-data.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
  schoolId:any='';
  schools: keyValue[] = [];

  constructor(private loadPageScripts: LoadScriptsService, private schoolDataService: SchoolDataService, private cookieHelper: cookieHelper) {
    this.getSchoolData() ;
  }
  schoolData = new schoolData();

   async getSchoolData() {
    this.schoolId=this.cookieHelper.getCookie("schoolid");
   
         if (this.schoolId == null || this.schoolId == undefined || this.schoolId == "") {
          await this.schoolDataService.getData().subscribe((data: any) => {
            this.schoolId=data[0].key;
            
            this.cookieHelper.setCookie("schoolid", this.schoolId.key.toString(),10000);

           }) 
        }
       
       

  
  }
   setSchooData(){
   this.schoolDataService.getSchoolData().subscribe((data: any) => 
    {
      this.schoolData = data;

    })

  }

  ngOnInit() {
    this.loadPageScripts.homePageScripts();
    this.setSchooData();

  }

}

