import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class StagesService {

  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }
  getStages() {
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/stages/getStages");
  }
}
