import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function gardiansValidator(): ValidatorFn {
  return (group: AbstractControl): ValidationErrors | null => {
    const name = group.get("name")?.value;
    const phoneNumber = group.get("phoneNumber")?.value;
    const nationalId = group.get("nationalId")?.value;
    const relationWithStudent = group.get("relationWithStudent")?.value;
    const gardiantype = group.get("gardianType")?.value;

    if ((name === '' || phoneNumber === '' || nationalId === '' || relationWithStudent === '') && gardiantype === 'other') {
      return { notValid: true };
    } else {
      return null;
    }
  }
}
