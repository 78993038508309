import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { keyValue } from '../models/keyValue';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolsSelectService {
  valueFromSelect:string="";

  constructor(private httpclient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }

  getSchools() {
    return this.httpclient.get(this.readingSettingsData.getSchoolDomain() + "/website/schoolsData/getData")
  }

}
