import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-hidden-tab-right',
  templateUrl: './hidden-tab-right.component.html',
  styleUrls: ['./hidden-tab-right.component.css']
})
export class HiddenTabRightComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
