import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';
@Injectable({
    providedIn: 'root'
  })
  export class NewDetailsService {
    constructor(private httpClient: HttpClient, private readindSettingsData: ReadingSettingsDataService) { }
    getNewById(newId: number) {
        return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/newDetails/getDetailById?newsId=" + newId)
      }
  }
 