import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { cookieHelper } from './cookieHelper';

@Injectable()
export class basicInterceptor implements HttpInterceptor {
    constructor(private cookieHelper:cookieHelper) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      var language=localStorage.getItem('language') as string;
            request = request.clone({
                setHeaders: {
                    schoolid: this.cookieHelper.getCookie("schoolid"),
                    language:language
                 }
            });
        return next.handle(request);
    }
}
