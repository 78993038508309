import { Component, Input, OnInit } from '@angular/core';
import { aboutMiniData } from 'src/app/core/models/aboutMiniData';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { AboutService } from 'src/app/core/services/about.service';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';

@Component({
  selector: 'app-aboutsection',
  templateUrl: './aboutSection.component.html',
  styleUrls: ['./aboutSection.component.css']
})
export class AboutSectionComponent implements OnInit {
  pathData = new attachmentsPaths();
  aboutData = new aboutMiniData();
  @Input() schoolName: string = '';
  domainUrl: string = this.readingSettingsData.getSchoolDomain();
  constructor(private aboutService: AboutService, private readingSettingsData: ReadingSettingsDataService) {
  }
  ngOnInit(): void {
    this.aboutService.getMiniData().subscribe((data: any) => {
      this.aboutData = data
    })
  }

}
