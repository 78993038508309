<div id="wrapper">
  <!--header component-->
  <app-header [schoolData]="schoolData"></app-header>
  <!--navbar component-->
  <app-navbar></app-navbar>


  <content>

    <!-- <input id="hamburger" class="hamburger" type="checkbox" />
    <label class="hamburger" for="hamburger">
      <i></i>

    </label> -->
    <section class="drawer-list">
      <ul>
        <li><a href="#">dashboard</a></li>
        <li><a href="#">notifications</a></li>
        <li><a href="#">system administration</a></li>
        <li><a href="#">support</a></li>
      </ul>
    </section>
  </content>

  <!-- <app-hidden-tab-right></app-hidden-tab-right> -->
  <app-slider-for-home></app-slider-for-home>
  <!-- Main Holder -->
  <div id="mainholder">
    <app-aboutsection [schoolName]="schoolData.name"></app-aboutsection>
    <app-newsSection></app-newsSection>
    <app-mapSection [schoolMap]="schoolData.googleMap"></app-mapSection>

  </div>
</div>
<app-school-statistics></app-school-statistics>
<app-footer [schoolData]="schoolData"></app-footer>
