<div class="ColumnLeft">
  <nav id="SideHeadingPages" class="SideHeading">
    <h2>{{'RelatedPages'|translate}}</h2>
    <ul class="SideList">
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
      <li><a href="#" id="">{{'pageLink'|translate}}</a></li>
    </ul>
  </nav>
</div>
