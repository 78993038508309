import { TermOfJoinData } from "./TermOfJoinData";

export class TermOfJoinDetaisData{
  ID :number=0;
  BranchID:number=0;
  CategoryID:number=0;
  UserID :number=0;
  ImageUrl:string='';
  Title:string='';
  SubTitle:string=''; 
  Show:boolean=false;
  TermOfJoinDetailsVMs:TermOfJoinsDetailsData[]=[];
  
  }
  export class TermOfJoinsDetailsData{
    TermOfJoinsDetailsID:number=0;
    TermOfJoinsID:number=0;
    HeadLine:string='';
    Details:string='';
    Attachments:string[]=[];
}

 