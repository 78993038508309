<ul class="SideList SideHeading" id="SideHeadingDiary" style="display: block;">
  <li *ngFor="let event of latestEvents">
    <a href="#" class="clearfix" style='cursor: text; text-decoration:none;'>

      <p class="date"><span>{{event.startDate|date:"dd"}}</span> <em>{{event.startDate|date:"MMM"}}</em></p>
      <p class="title"><span>{{pathData.events+event.title}}</span></p>
    </a>
  </li>
  <div class="more-links">
    <a routerLink="/events" class="more">{{'allEvents'|translate}}</a>
  </div>
</ul>
