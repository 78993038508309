import { Component } from '@angular/core';
import { Title,Meta } from '@angular/platform-browser';
import { cookieHelper } from 'src/app/core/helpers/cookieHelper';
import { schoolData } from './core/models/schoolData';
import { ReadingSettingsDataService } from './core/services/commonServices/reading-settings-data.service';
import { SchoolDataService } from './core/services/school-data.service';
const content = require('src/assets/setupData.json');
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'schoolPublicUser';
  favIcon: HTMLLinkElement | any = document.querySelector("#appIcon");
  description: HTMLElement | any = document.querySelector("#description");
  pagetitle: HTMLElement | any = document.querySelector("#pagetitle");
  schoolData:schoolData|any;
  doaminName:string;
  constructor(private readingsettingData: ReadingSettingsDataService, private schoolDataService: SchoolDataService,private readingSettingsData: ReadingSettingsDataService,
    private cookieHelper: cookieHelper,public titleService:Title,public MetaService:Meta) {
    this.doaminName=this.readingSettingsData.getSchoolDomain();
    this.schoolDataService.getSchoolData().subscribe((data: any) => 
    {this.schoolData = data
      this.titleService.setTitle(this.schoolData.name);
      this.favIcon.href=`${this.doaminName}${this.schoolData.logo}`;
      // this.description.attributes.content.value=this.schoolData.About;
      // this.pagetitle.innerHTML = this.schoolData.name
      debugger;
      this.MetaService.addTags([
              { name: 'keywords', content: this.schoolData.name },
      { name: 'robots', content: 'index, follow' },
      { name: 'author', content: 'Ronak Patel' },
      // { name: 'date', content: '2021-05-17', scheme: 'YYYY-MM-DD' },
      { name: 'description', content: this.schoolData.About },
      { charset: 'UTF-8' }
      ])
    });
    
        
  
   
  }
  
}
