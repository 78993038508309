<div *ngIf="!sliderIsVedio">
<div id="slider" class="h" >
  <!-- <div class="tagline">
    <<h2><span>"</span>{{'sliderQuot'|translate}}<span>"</span></h2>
</div> -->
  <div class="slider-links">
    <a routerLink="/news" class="slider-link"><em>{{'news'|translate}}</em></a>
    <a routerLink="/apply" class=" slider-link"><em>{{'applynow'|translate}}</em></a>
    <a routerLink="/jobs" class=" slider-link"><em>{{'Jobs'|translate}}</em></a>
    <!-- <a href="#" class="slider-link"><em>{{'pageLink'|translate}}</em></a>
    <a href="#" class="slider-link"><em>{{'pageLink'|translate}}</em></a> -->
  </div>

  <div class="news-diary">
    <ul class="diary-news">
      <!-- <li id="news-tab" >{{'news'|translate}}</li> -->
      <li id="diary-tab" class="current">{{'events'|translate}}</li>
    </ul>
    <app-latest-events></app-latest-events>
    <!-- <app-latest-news></app-latest-news> -->
  </div>
</div>

</div>

<div id="" *ngIf="sliderIsVedio">

  
    <div id="videoSlider">
      <video autoplay="" muted="" loop="" style="width:100%"  [src]="videoSrc">
    </video>
    </div>
    <div class="slider-links">
      <a routerLink="/news" class="slider-link"><em>{{'news'|translate}}</em></a>
      <a routerLink="/apply" class=" slider-link"><em>{{'applynow'|translate}}</em></a>
      <a routerLink="/jobs" class=" slider-link"><em>{{'Jobs'|translate}}</em></a>
   
    </div>
    <div class="news-diary-Video">
      <ul class="diary-news">
        <!-- <li id="news-tab" >{{'news'|translate}}</li> -->
        <li id="diary-tab" class="current">{{'events'|translate}}</li>
      </ul>
      <app-latest-events></app-latest-events>
      <!-- <app-latest-news></app-latest-news> -->
    </div>
 
</div>
