import { Component, OnInit } from '@angular/core';
import { schoolStatisticsData } from 'src/app/core/models/schoolStatisticsData';
import { SchoolStatisticsService } from 'src/app/core/services/school-statistics.service';
import { SchoolsSelectService } from 'src/app/core/services/schools-select.service';

@Component({
  selector: 'app-school-statistics',
  templateUrl: './school-statistics.component.html',
  styleUrls: ['./school-statistics.component.css']
})
export class SchoolStatisticsComponent implements OnInit {
statisticsData=new schoolStatisticsData();
  constructor(private schoolStatistics:SchoolStatisticsService) {
    this.loadSchoolData();
   }

  ngOnInit(): void {
  }
  loadSchoolData(){
  this.schoolStatistics.getStatistics().subscribe((data:any) =>{
    this.statisticsData=data;
  } );


}
}
