<section class="map-section">
  <div id="map">
    <!-- <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1242.5114148338357!2d-0.09992090179806788!3d51.476095250182595!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x41703470ec3260f5%3A0xddbeb985ddf14d1d!2sSacred%20Heart%20Catholic%20Secondary%20School!5e0!3m2!1sen!2suk!4v1584363047997!5m2!1sen!2suk"
      width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
      tabindex="0"></iframe> -->
      <iframe
       [src]="schoolMap | safe"
      width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
      tabindex="0"></iframe>
  </div>
</section>
