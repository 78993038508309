import { Component, OnInit } from '@angular/core';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { SliderService } from 'src/app/core/services/slider.service';
declare const loadScliderImages: any;
@Component({
  selector: 'app-slider-for-home',
  templateUrl: './slider-for-home.component.html',
  styleUrls: ['./slider-for-home.component.css']
})
export class SliderForHomeComponent implements OnInit {
  sliderIsVedio:boolean=false;
  videoSrc:string='';
  constructor(private sliderService: SliderService, private readingSettingsData: ReadingSettingsDataService) { 
    this.checkIfSliderIsVedio();

  }
  ngOnInit(): void {
    this.sliderService.getImages().subscribe(x => {
      if(this.sliderIsVedio&&x.length>0){
    
        this.videoSrc=this.readingSettingsData.getSchoolDomain()+x[0].img;
        

      }else{
      loadScliderImages(x, this.readingSettingsData.getSchoolDomain());
      }
    })
  }
  checkIfSliderIsVedio():void{

    this.sliderService.checkIfSliderIsVedio().subscribe(x => {
      this.sliderIsVedio=x;
    })

  }
}
