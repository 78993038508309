import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolStatisticsService {

  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }


  getStatistics() {
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/schoolsData/schoolStatistics")
  }
}
