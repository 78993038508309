<form [formGroup]="formData" (ngSubmit)="onSubmit()" class="form-2" autocomplete="off">
  <h1><span class="log-in">{{'LogIn'|translate}}</span></h1>

  <div>{{message}}</div>
  <p class="float">
    <label for="login"><i class="fa fa-user"></i>{{'userName'|translate}}</label>
    <input type="text" formControlName="name">
  </p>
  <p class="float">
    <label for="password"><i class="fa fa-lock"></i>{{'passWord'|translate}}</label>
    <input type="password" formControlName="password" class="showpassword">
  </p>
  <p class="clearfix">
    <input type="submit" name="submit" value="{{'LogIn'|translate}}">
  </p>
</form>
