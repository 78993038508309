import { Component, OnInit } from '@angular/core';
import { eventsData } from 'src/app/core/models/eventsData';
import { EventsService } from 'src/app/core/services/events.service';
import { paginationConfig } from 'src/app/core/models/paginationConfig';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.css']
})

export class EventsComponent implements OnInit {
  pathData = new attachmentsPaths();
  config = new paginationConfig();
  domainUrl: string = this.settingsData.getSchoolDomain();
  constructor(private eventsService: EventsService, private settingsData: ReadingSettingsDataService, private router: Router) {
    this.loadEventsData();
    setTimeout(() => {
    }, 15000);
  }

  loadEventsData() {
    this.eventsService.getEventsByPage(this.config).subscribe((data: any) => {
      this.eventsList = data.data;
      this.config.totalItems = data.totalRecords;
    })
  }
  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.loadEventsData();
  }

  navigateToEventDetails(event: eventsData) {
    this.router.navigate(['/events', event.id])
  }
  eventsList: eventsData[] = [];
  ngOnInit(): void {
  }
}
