import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class SchoolDataService {

  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }
  getSchoolData() {
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/schoolsData/getOneSchoolData");
  }
  getData(){
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/schoolsData/getData");


  }
}
