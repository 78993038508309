import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainPageComponent } from './layout/main-page/main-page.component';
import { AboutComponent } from './pages/about/about.component';
import { ApplyNowComponent } from './pages/apply-now/apply-now.component';
import { TermOfJoinDetailsComponent } from './pages/apply-now/termofJoindetails/termofJoindetails-details.component';
import { EventDetailsComponent } from './pages/events/event-details/event-details.component';
import { EventsComponent } from './pages/events/events.component';
import { HomeComponent } from './pages/home/home.component';
import { NewDetailsComponent } from './pages/news/new-details/new-details/new-details.component';
import { JobDetailsComponent } from './pages/jobs/job-details/job-details.component';
import { NewsComponent } from './pages/news/news.component';
import { JobsComponent } from './pages/jobs/jobs.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ModalComponent } from './modal/modal.component';


const routes: Routes = [
  { path: "", component: HomeComponent },
  {
    path: "", component: MainPageComponent, children: [
      { path: "events", component: EventsComponent },
      { path: "events/:id", component: EventDetailsComponent },
      { path: "news", component: NewsComponent },
      { path: "jobs", component: JobsComponent },
      { path: "apply", component: ApplyNowComponent },
      { path: "about", component: AboutComponent },
      { path: "admin", component: RedirectComponent },
      { path: "JobDetails/:newId", component: JobDetailsComponent },
      { path: "newDetails/:newId", component: NewDetailsComponent },
      { path: "TermOfJoinDetails/:newId", component: TermOfJoinDetailsComponent },
      { path: "modal", component: ModalComponent }

    ]
  },];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

