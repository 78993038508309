import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { paginationConfig } from '../models/paginationConfig';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private httpclient: HttpClient, private readingSettingsData: ReadingSettingsDataService) {
  }
  getLatesEvents() {
    return this.httpclient.get(this.readingSettingsData.getSchoolDomain() + "/website/events/getLatestEvents")
  }

  getEventsByPage(pagingConfigs: paginationConfig) {
    return this.httpclient.get(this.readingSettingsData.getSchoolDomain() + "/website/events/getEventsByPage?pageNumber=" + pagingConfigs.currentPage + "&&size=" + pagingConfigs.itemsPerPage)
  }

  getEventById(eventId: number) {
    return this.httpclient.get(this.readingSettingsData.getSchoolDomain() + "/website/events/getById?eventId=" + eventId)
  }
}
