<section style="display: none;" id="animated-stats">
  <div class="wrapper">
    <h2>{{'SchoolStatistics'|translate}}</h2>
    <div class="performance-box">
      <div id="performance-group">
        <div class="donut-item">
          <div id="performance" data-donut="30"></div>
          <p>{{statisticsData.result1}}</p>
        </div>
        <div class="donut-item">
          <div class="white-text" id="performance2" data-donut="50"></div>
          <p>{{statisticsData.result2}}</p>
        </div>
        <div class="donut-item">
          <div id="performance3" data-donut="60"></div>
          <p>{{statisticsData.result3}}</p>
        </div>
        <div class="donut-item">
          <div id="performance4" data-donut="70"></div>
          <p>{{statisticsData.result4}}</p>
        </div>
        <div class="donut-item">
          <div id="performance5" data-donut="40"></div>
          <p>{{statisticsData.result5}}</p>
        </div>
        <div class="donut-item">
          <div class="white-text" id="performance6" data-donut="90"></div>
          <p><strong data-stringify-type="bold">{{statisticsData.result6}}</strong></p>
        </div>
      </div>
    </div>
  </div>
</section>
