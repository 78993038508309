import { Component, OnInit } from '@angular/core';
import { JobsService } from 'src/app/core/services/jobs.service';
import { paginationConfig } from 'src/app/core/models/paginationConfig';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-jobs',
  templateUrl: './jobs.component.html',
  styleUrls: ['./jobs.component.css']
})
export class JobsComponent implements OnInit {
  config = new paginationConfig();
  domainUrl: string = this.readingSettingsData.getSchoolDomain();
  constructor(private JobsService: JobsService,private readingSettingsData: ReadingSettingsDataService,private router: Router) {
   }
  JobsData: any = [];
  loadJobsData(){
    this.JobsService.GetBranchJobs(this.config).pipe().toPromise().then((data:any)=>{
      console.log(data);
      this.JobsData = data.data;
      this.config.totalItems = data.totalRecords;
      $('#JobResults').show("slow")
    });
    console.log(this.JobsData);
  }
  pageChange(newPage: number) {
    this.config.currentPage = newPage;
    this.loadJobsData();
  }
  ngOnInit(): void {
    $('#JobResults').hide();
    this.loadJobsData();
  }
  NavigateToJobDetails(jobItem:any ) {
    this.router.navigate(['/JobDetails', jobItem.Id])

  }
  

}
