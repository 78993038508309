import { Component, OnInit } from '@angular/core';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { eventsData } from 'src/app/core/models/eventsData';
import { EventsService } from 'src/app/core/services/events.service';

@Component({
  selector: 'app-latest-events',
  templateUrl: './latest-events.component.html',
  styleUrls: ['./latest-events.component.css']
})
export class LatestEventsComponent implements OnInit {
  pathData=new attachmentsPaths();
  constructor(private eventsService:EventsService) {
    this.eventsService.getLatesEvents().subscribe((data:any)=>{
      this.latestEvents=data;
    } )
   }
  latestEvents:eventsData[]=[];
  ngOnInit(): void {
  }
}
