import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class AboutService {
  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }

  getMiniData() {
    const headerDict = {
      'Accept': 'application/json',
    }
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/about/getAboutSchoolMiniData", { headers: headerDict });
  }

  getFullData() {
    const headerDict = {
      'Accept': 'application/json',
    }
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/about/getAboutSchoolFullData", { headers: headerDict });
  }

}


