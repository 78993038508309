import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function nullFieldsValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;

    if (value == null) {
      return { isNull: true };
    } else {
      return null;
    }


  }
}
