import { Injectable } from '@angular/core';
import { GlobalFunctionsService } from './global-functions.service';

@Injectable({
  providedIn: 'root'
})
export class LoadScriptsService {

  constructor(private globalFunctionsService: GlobalFunctionsService) {
  }

  homePageScripts() {
    this.globalFunctionsService.loadExternalScript(["/assets/pages-custom-scripts/home.js"]);
  }
  LayoutScripts() {
    this.globalFunctionsService.loadExternalScript(["/assets/pages-custom-scripts/layoutScript.js"]);
  }
  applyNowScripts() {
    this.globalFunctionsService.loadExternalScript([

      // "/assets/inc/ckeditor/ckeditor.js",
      // "/assets/inc/fileuploader/filepond-plugin-image-preview.min.js",
      // "/assets/inc/fileuploader/filepond.min.js",
      // "/assets/inc/fileuploader/filepond.jquery.js",
      // "/assets/inc/datepicker/moment.min.js",
      // "/assets/inc/datepicker/daterangepicker.min.js",
      "/assets/inc/carousel/owl.carousel.min.js",
      "/assets/inc/js/jquery-steps.min.js",
      "/assets/pages-custom-scripts/applyNow.js"
    ])
  }
}
