import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { paginationConfig } from '../models/paginationConfig';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';
@Injectable({
  providedIn: 'root'
})
export class JobsService {

  constructor(private httpClient: HttpClient, private readindSettingsData: ReadingSettingsDataService) { }
  GetBranchJobs(pagingConfigs: paginationConfig) {
    const headerDict = {
      'Accept': 'application/json',
    }
    return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/jobs/GetAllJobs?pageNumber=" + pagingConfigs.currentPage + "&&size=" + pagingConfigs.itemsPerPage, { headers: headerDict });
  }
  getjobById(jobId: number) {
    return this.httpClient.get(this.readindSettingsData.getSchoolDomain() + "/website/jobs/getDetailById?jobId=" + jobId)
  }
  PostJobApplyRequest(obj:any){
    var form: any = new FormData();
    form.append("FullName", obj.FullName);
    form.append("Email", obj.Email);
    form.append("mobilePhone", obj.mobilePhone);
    form.append("PhoneNumber", obj.PhoneNumber);
    form.append("CvPath", obj.CvPath);
    form.append("JobApplicationId", obj.JobApplicationId);

    return this.httpClient.post(this.readindSettingsData.getSchoolDomain() + "/website/jobs/PostJobApplyRequest", form, {
      reportProgress: true,
      observe: 'events'
    });
  }
}
