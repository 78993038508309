import { Component, Input, OnInit } from '@angular/core';
import { aboutMiniData } from 'src/app/core/models/aboutMiniData';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { AboutService } from 'src/app/core/services/about.service';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';

@Component({
  selector: 'app-mapSection',
  templateUrl: './mapSection.component.html',
  styleUrls: ['./mapSection.component.css']
})
export class mapSectionComponent implements OnInit {
  pathData = new attachmentsPaths();
  aboutData = new aboutMiniData();

  @Input() schoolName: string = '';
  @Input() schoolMap: string = '';

  domainUrl: string = this.readingSettingsData.getSchoolDomain();
  constructor(private aboutService: AboutService, private readingSettingsData: ReadingSettingsDataService) {    

  }
  ngOnInit(): void {
    this.aboutService.getMiniData().subscribe((data: any) => {
      this.aboutData = data;
      this.schoolMap = this.schoolMap + "&embedded=true";

      console.log(this.schoolMap)

      
    })
  }

}
