import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { Component, Input, SimpleChanges, OnChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';


import { loginForm } from 'src/app/core/models/loginForm';
import { LoginService } from 'src/app/core/services/login.service';
import { ReadingSettingsDataService } from 'src/app/core/services/commonServices/reading-settings-data.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnChanges, OnInit {
  constructor(private formBuilder: FormBuilder, private loginService: LoginService, private readingSettingsData: ReadingSettingsDataService) { }
  formData = this.formBuilder.group(new loginForm());
  @Input() message: string = 'from child';
  @Output() changeMessageValue = new EventEmitter<string>();
  ngOnChanges(changes: SimpleChanges) {
    if (changes["message"]) {
      this.message = changes["message"].currentValue;
    }
  }
  ngOnInit() {
  }
  onSubmit() {
    this.loginService.login({ name: this.formData.controls['name'].value, password: this.formData.controls['password'].value }).subscribe(result => {

      if (result === null) {
        this.message = "password or user name is wrong";
        this.changeMessageValue.emit(this.message);
      }
      else {
        window.open(this.readingSettingsData.getSchoolDomain() + "/user/websiteLogin?cookie=" + result.userToken, "_self")
      }
    },
      error => {
        this.message = "حصل خطأ ما";
        this.changeMessageValue.emit(this.message);
      });
  }
  setCookie(name: string, value: string, days: number) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }
}




