import { Injectable } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GlobalFunctionsService {

  constructor() { }

  public loadExternalScript(urls: string[]) {

    const body = <HTMLDivElement>document.body;
    urls.forEach(function (e) {
      const script = document.createElement('script');
      script.innerHTML = '';
      script.src = e;
      script.async = true;
      script.defer = true;
      body.appendChild(script);
    })

  }

  //   getFormValidationErrors(form: FormGroup) {

  //     String result = [];
  //     Object.keys(form.controls).forEach(key => {

  //       const controlErrors: ValidationErrors = form.get(key).errors;
  //       if (controlErrors) {
  //         Object.keys(controlErrors).forEach(keyError => {
  //           result.push({
  //             'control': key
  //           });
  //         });
  //       }
  //     });

  //     return result;
  //   }
}
