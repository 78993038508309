import { newsData } from "./newsData";

export class newDetaisData{
  ID :number=0;
  BranchID:number=0;
  CategoryID:number=0;
  UserID :number=0;
  ImageUrl:string='';
  Title:string='';
  SubTitle:string='';
  DatetimeOfPublish:string='';
  DateOfLastUpdate:string='';
  NewsSource:string='';
  NewsPlace:string='';
  KeyWords:string='';
  EnableAddComments:boolean=false;
  NewsDetailsVMs:newsDetailsData[]=[];
  
  }
  export class newsDetailsData{
    NewsDetailsID:number=0;
    NewsID:number=0;
    HeadLine:string='';
    Details:string='';
    Attachments:string[]=[];
}

 