<section class="blog-section sec-padding bg-grey">
  <div class="container">
    <div class="section-title text-center mb-5">
      <h2 class="my-0">{{'latestNews'|translate}}</h2>
    </div>
    <div class="row">
      <div *ngFor="let news of latestNewsData" class="col-lg-3 col-md-6 col-sm-6 mb-3">
        <div class="blog-card card">
          <div class="card-img">
            <img src="https://educlever.beplusthemes.com/university/wp-content/uploads/2017/06/edu_event6-614x346.jpg"
              class="img-fluid">
            <p class="blog-date mb-0">{{news.date}}</p>
          </div>
          <div class="card-content card-body">
            <h2 class="mt-3 mb-1">{{news.title}}</h2>
            <p>{{news.subtitle}} </p>
            <a (click)="getNewsDetails(news.id)">{{'readMore'|translate}}</a>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
