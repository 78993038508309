import { Component, OnInit } from '@angular/core';
import { ReadingSettingsDataService } from '../core/services/commonServices/reading-settings-data.service';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.css']
})
export class RedirectComponent implements OnInit {
  domainUrl: string = this.settingsData.getSchoolDomain();
  constructor(private settingsData: ReadingSettingsDataService) { }
  ngOnInit(): void {
    window.location.href = this.domainUrl + "/Admin";
  }
}
