<!-- <section id="welcome">
  <div class="wrapper">
    <h2><span>{{'WelcomeTo'|translate}}</span>{{schoolName}}</h2>
    <img style="width: 250px;height: 250px;" id="welcome-img" src="{{domainUrl+pathData.about+aboutData.imgUrl}}" />
    <p>{{aboutData.text}}</p>
    <div id="ofsted-quotes">
      <ul id="bxslider">
        <li>{{'aboutQuot'|translate}}</li>
      </ul>
    </div>
  </div>
</section> -->
<section class="about-section sec-padding bg-white">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
        <div class="about-content text-center">
          <div class="content-icon mb-5">
            <img src="https://educlever.beplusthemes.com/university/wp-content/uploads/2019/05/forma1.png"
              class="img-fluid">
          </div>
          <h1 class="mb-3"><span>{{'WelcomeTo'|translate}}</span> {{schoolName}}</h1>
          <p class="mb-4" [innerHTML]="aboutData.text"></p>
          <a routerLink="/about" href="#" class="main-btn">{{'readMore'|translate}}</a>
        </div>
      </div>
      <div class="col-md-5">
        <div class="about-img text-center">
          <img src="{{domainUrl+pathData.about+aboutData.imgUrl}}" class="img-fluid">
        </div>
      </div>
    </div>
  </div>
</section>
