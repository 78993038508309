import { Component, Input, OnInit } from '@angular/core';
import { schoolData } from 'src/app/core/models/schoolData';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  signinMessage: string = '';
  constructor() { }
  @Input() schoolData = new schoolData()
  riseSignInModal() {
    this.signinMessage = '';
  }
  setMessageValue(data: string) { this.signinMessage = data; }
  ngOnInit(): void {
  }
 
}
