import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css']
})
export class LanguageSelectComponent implements OnInit {
  currentLanguage: string = '';
  constructor(public translate: TranslateService, private router: Router) {
    translate.addLangs(['en', 'ar']);
    if (localStorage.getItem('language')) {
      const browserLang = localStorage.getItem('language') as string;
      translate.use(browserLang);
      this.currentLanguage = browserLang;
      let direction =
      localStorage.getItem('language') === "ar"
        ? "rtl"
        : "ltr";
    document.documentElement.dir = direction;
    } else {
      localStorage.setItem('language', 'en');
      translate.setDefaultLang('en');
      this.currentLanguage = 'en';
    }
  }
  changeLang(language: string) {
    localStorage.setItem('language', language);
    this.translate.use(language);
    let currentUrl = this.router.url;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate([currentUrl]);
    this.currentLanguage = language;
         
  }
  ngOnInit(): void {
  }

}
