import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { attachmentsPaths } from 'src/app/core/models/attachmentsPaths';
import { newsMiniData } from 'src/app/core/models/newsMiniData';
import { NewsService } from 'src/app/core/services/news.service';


@Component({
  selector: 'app-newsSection',
  templateUrl: './newsSection.component.html',
  styleUrls: ['./newsSection.component.css']
})
export class newsSectionComponent implements OnInit {
  pathData = new attachmentsPaths();
  latestNewsData: newsMiniData[] = [];
  constructor(private NewsService: NewsService, private router: Router) {
    NewsService.getLatestNews().subscribe((data: any) => {
      this.latestNewsData = data;
    })
  }
  ngOnInit(): void {
  }
  getNewsDetails(newsId: number) {
    this.router.navigate(['/newDetails', newsId])
  }

}
