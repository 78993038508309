import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ReadingSettingsDataService } from './commonServices/reading-settings-data.service';

@Injectable({
  providedIn: 'root'
})
export class StudentApplyService {
  constructor(private httpClient: HttpClient, private readingSettingsData: ReadingSettingsDataService) { }

  sendData(formValues: FormGroup,birthDateFile: any,motherPassport: any,fatherPassport: any,studentPassport: any,PersonalImage:any) {
    var form: any = new FormData();
    //student data
    let devisionId = formValues.get(['studentData', 'devision'])?.value;
    if (devisionId !== null) {
      form.append("devision", formValues.get(['studentData', 'devision'])?.value);
    }
    form.append("studentName", formValues.get(['studentData', 'name'])?.value);
    form.append("StudentNameEngilsh", formValues.get(['studentData', 'name'])?.value);
    form.append("studentGender", formValues.get(['studentData', 'gender'])?.value);
    form.append("departmentId", formValues.get(['studentData', 'department'])?.value);
    form.append("gradeId", formValues.get(['studentData', 'grade'])?.value);
    form.append("studentNationalId", formValues.get(['studentData', 'nationalId'])?.value);
    form.append("studentBirthDate", formValues.get(['studentData', 'birthDate'])?.value);
    form.append("StudentHomeAddress", formValues.get(['studentData', 'homeAddress'])?.value);
    form.append("StudentPhoneNumber", formValues.get(['studentData', 'phoneNumber'])?.value);
    form.append("StudentNationality", formValues.get(['studentData', 'nationality'])?.value);
    form.append("StudentReligion", formValues.get(['studentData', 'religion'])?.value);
    form.append("secondLanguage", formValues.get(['studentData', 'secondLanguage'])?.value);
    form.append("lastSchool", formValues.get(['studentData', 'lastSchool'])?.value);
    form.append("relativesNumber", formValues.get(['studentData', 'relativesNumber'])?.value);
 
    form.append("StuPersonalImage", PersonalImage);
    form.append("studentBirthDateCertificate", birthDateFile);
    form.append("studentPassport", studentPassport);
    form.append("fatherPassport", fatherPassport);
    form.append("motherPassport", motherPassport);
  
    $(formValues.get(['studentData', 'brotherslit'])?.value).each(function (index) {
      form.append('brotherslist[' + index + '][name]', formValues.get(['studentData', 'brotherslit'])?.value[index].name);
      form.append('brotherslist[' + index+ '][grad]', formValues.get(['studentData', 'brotherslit'])?.value[index].grad);

  });
    
  
    //father Data
    form.append("fatherName", formValues.get(['fatherData', 'name'])?.value);
    form.append("fatherNameEngilsh", formValues.get(['fatherData', 'nameEngilsh'])?.value);
    form.append("fatherPhoneNumber", formValues.get(['fatherData', 'phoneNumber'])?.value);
    form.append("fatherEmail", formValues.get(['fatherData', 'email'])?.value);
    form.append("fatherNationalId", formValues.get(['fatherData', 'nationalId'])?.value);
    form.append("fatherCompany", formValues.get(['fatherData', 'fatherCompany'])?.value);
    form.append("fatherJob", formValues.get(['fatherData', 'fatherjob'])?.value);
    form.append("FatherQualification", formValues.get(['fatherData', 'FatherQualification'])?.value);

    form.append("fahterNationalityId", formValues.get(['fatherData', 'fathernationality'])?.value);

    //mother data
    form.append("motherName", formValues.get(['motherData', 'name'])?.value);
    form.append("motherNameEngilsh", formValues.get(['motherData', 'nameEngilsh'])?.value);
    form.append("motherPhoneNumber", formValues.get(['motherData', 'phoneNumber'])?.value);
    form.append("motherEmail", formValues.get(['motherData', 'email'])?.value);
    form.append("motherNationalId", formValues.get(['motherData', 'nationalId'])?.value);
    form.append("motherJob", formValues.get(['motherData', 'motherjob'])?.value);
    form.append("MotherQualification", formValues.get(['motherData', 'MotherQualification'])?.value);

    form.append("motherSocialStatusId", formValues.get(['motherData', 'motherSocialStatus'])?.value);
    form.append("legalGardian", formValues.get(['motherData', 'legalGardian'])?.value);
    form.append("motherNationalityId", formValues.get(['motherData', 'mothernationality'])?.value);

    //guardian data
    form.append("gardianType", formValues.get(['gardian', 'gardianType'])?.value);
    form.append("gardianName", formValues.get(['gardian', 'name'])?.value);
    form.append("gardianNameEngilsh", formValues.get(['gardian', 'nameEngilsh'])?.value);
    form.append("gardianPhoneNumber", formValues.get(['gardian', 'phoneNumber'])?.value);
    form.append("gardianEmail", formValues.get(['gardian', 'email'])?.value);
    form.append("gardianNationalId", formValues.get(['gardian', 'nationalId'])?.value);
    form.append("gardianRelationWithStudent", formValues.get(['gardian', 'relationWithStudent'])?.value);
    form.append("gardianNationalityId", formValues.get(['gardian', 'parentnationality'])?.value);
    form.append("gardianJob", formValues.get(['gardian', 'parentjob'])?.value);
// Enroment Accepted 
form.append("IsAcceptedEnrollment", formValues.get(['Enrollment', 'IsAcceptedEnrollment'])?.value);
// Medical Data /// 
form.append("EmergencyPersonName", formValues.get(['MedicalData', 'personName'])?.value);
form.append("EmergencyMobile", formValues.get(['MedicalData', 'EmergencyMobile'])?.value);
form.append("EmergencyEmail", formValues.get(['MedicalData', 'EmergencyEmail'])?.value);
form.append("EmergencyAddress", formValues.get(['MedicalData', 'emergencyAddress'])?.value);
form.append("previousMedicalStatus", formValues.get(['MedicalData', 'previousMedicalStatus'])?.value);
form.append("IsStudentTakeDrugs", formValues.get(['MedicalData', 'IsStudentTakeDrugs'])?.value=='yes'?true:false);
form.append("SchoolResponsibilty", formValues.get(['MedicalData', 'SchoolResponsibilty'])?.value);
form.append("FamilyDoctorRecommendations", formValues.get(['MedicalData', 'FamilyDoctorRecommendations'])?.value);
form.append("StudentDoActivities", formValues.get(['MedicalData', 'StudentDoActivities'])?.value=='yes'?true:false);
form.append("StudentRetardationType", formValues.get(['MedicalData', 'StudentRetardationType'])?.value);
form.append("StudentOrder", formValues.get(['MedicalData', 'studentOrder'])?.value);


    return this.httpClient.post(this.readingSettingsData.getSchoolDomain() + "/website/studentsApply/saveApply", form, {
      reportProgress: true,
      observe: 'events'
    });
  
  
  
  
  
  
  }

  SearchByNationalId(nationalId:any){
    return this.httpClient.get(this.readingSettingsData.getSchoolDomain() + "/website/studentsApply/SearchByNationalId?nationalId=" + nationalId);
  }
}
